@import "../../../styles/main.scss";
.location-card__molecule {
  display: flex;
  margin: 10px;
  flex-direction: column;
  cursor: pointer;
  border-radius: 4px;
  .location-card__molecule-house-image {
    width: 100%;
    height: 70%;
    border-radius: 20px;
  }

  .location-card__molecule-text {
    padding: 10px;

    .location-card__molecule-name {
      margin-bottom: 5px;
    }
    .location-card__molecule-details {
      margin-bottom: 3px;
    }
  }
  &:hover {
    @include box-shadow;
  }
}
