@import "../../../../../styles/main.scss";

.search-bar_AddMember {
  width: 100%;

  .searchBarandResults_wrapper {
    position: relative;
    margin-right: 5px;
    flex: 1;
  }

  input:focus {
    background-color: $primary-white;
  }
  .search-results-dropdown {
    position: absolute;
    top: 48px;
    width: 100%;
    @include box-shadow;
    max-height: 300px;
    overflow: auto;
  }

  .show-permissions {
    display: flex;
    column-gap: 20px;
  }
}
