@import "../../../../../styles/main.scss";

// .bullet_text {
//   padding: 4px;
//   font-size: 1.5em;
//   margin: auto;
//   display: flex;
//   .timestamp_bullet-text {
//     margin-top: 4px;
//     color: $primary-light-blue;
//   }
// }
// .bullet-server-text {
//   position: absolute;
//   left: 5px;
//   color: green;
// }

.bullet_container {
  display: flex;
  padding: 10px;
  max-height: 12%;
  background-color: white;
  border-radius: 8px;
 // border: 1px solid lightgrey;
 border-bottom: 1px solid lightgray;
  margin-bottom: 10px;
  position: relative;
  align-items: center;
  width: 70%;
  margin: 10px auto;
  .bullet-text_container-only{
    text-align: center;
    display: inline-flex;
    position: relative;
    align-items: center;
    width: 65%;
    // .bullet_text {

    // }
  }

  .actions {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 30%;
    right: 10px;
    text-align: center;
    // .timestamp_bullet-text {

    // }
  }
}


