@import "../../../../../../../styles/main.scss";

.site_edit-container {
  .site_edit {
    width: 70%;
    margin: auto;
    .edit-update-button {
      margin-right: 10px;
    }
  }

  .map-container {
    .map {
      width: 70%;
      height: 250px;
      margin: auto;
    }
  }
}

@media (max-width: $mobile) {
  .site_edit-container {
    .site_edit {
      width: 90%;
    }
  }
}
