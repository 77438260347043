@import "../../../styles/main.scss";
.msg-slide{
  height: 100%;
    bottom: 0px;
  left: 10px;
  text-align: center;
  position: absolute;
  .msg-slide__molecule {
    display: flex;
    position: relative;
    margin-top: 10px;
    flex-direction: column;
    cursor: pointer;
    border-radius: 4px;
    .msg-slide__molecule-house-image {
      // width: 40px;
      // height: 40px;
      // padding-bottom: 3px;
      // margin: auto;
      // margin-top: 10px;
      // border-radius: 200px;
    }
    .msg-slide__molecule-text {
      height: fit-content;
      text-align: center;
      .msg-slide__molecule-name {
        color: #000000;
        font-weight: 800;
      }
      .msg-slide__molecule-details {
        margin-bottom: 3px;
      }
    }

    &:hover {
      @include box-shadow;
    }
  }

  .msg-slide-active{
    top: 30px;
   // border-bottom: 1px solid lightgray;
    .msg-slide-active-btn{
      //position: relative;
      background-color: white;
      border-radius: 200px;
      border: 1px solid lightblue;
      margin: 10px 20px 5px 20px;
      top: 80px;
      height: 40px;
      width: 40px;
    }
  
  }
  .msg-slide-btn-text{
    margin: auto;
    overflow: clip;
    display: block;
    width: 60px;
    font-size: xx-small;

  }
  // .msg-slide-border{
  //   background-color: #7d7f8014;
  //   /* border-bottom: 5px solid #1a76d2; */
  //   border-bottom: 2px solid #7d7f8014;
  //   // margin-top: 20px;
  //   margin: 10px auto 10px auto;
  // }

  .msg-slide-btn{
    background-color: rgb(227, 238, 242);
    border: 1px solid lightblue;
    border-radius: 200px;
    margin: auto;
    margin-top: 10px;
    margin-left: 22.5px;
    height: 35px;
    width: 35px;
  }
}

.msg-slide__window {
  //visibility: hidden;

  .msg-slide-card-left__window-msgs{
    display:grid;
    margin-left: 5px;
    margin-top: 5px;
    
    .msg-slide-msgs__molecule-house-image{
      //position: relative;
      width: 20px;
      height: 20px;
      left: 0;
      border-radius: 20px;
  
    }
    .texter{
      position: relative;
      /* left: 5px; */
      text-align: left;
      padding: 2px 2px 2px 5px;
      left: 10px;
      color: #FFFFFF;
      background-color: #1a76d2;
      width: 220px;
      border-radius: 8px;
      margin-top: 5px;
      overflow-wrap: anywhere;
    }
  }
  .msg-slide-card-right__window-msgs{
    //position: absolute;
    display: grid;
    right: 8px;
    margin-top: 5px;
    
    .msg-slide-msgs__molecule-house-image{
      //position: relative;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      display: block;
      margin-left: auto;
      margin-right: 5px;
    }
    .texter{
      position: relative;
      /* left: 5px; */
      text-align: left;
      padding: 2px 2px 2px 5px;
      margin-left: auto;
      right: 10px;
      color: #000000;
      background-color: #00D9A7;
      width: 220px;
      border-radius: 8px;
      margin-top: 5px;
    }
  }
  
}