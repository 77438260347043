@import "../../../styles/main.scss";

.detail__molecule {
  border-bottom: 1px solid $tertiary-grey;
  padding: 20px 5px;
  display: flex;
  .name {
    width: 20%;
  }
  .value {
    width: 75%;
    padding: 0 2%;
  }
  .icon {
    text-align: end;
    cursor: pointer;
    &:hover {
      transition-duration: 0.1s;
      color: $primary-teal;
    }
  }
}

.detail__molecule.edit-mode {
  background-color: $secondary-grey;
}
