@import "../../../styles/main.scss";

.interior-page-heading-with-cta__molecule {
  border-bottom: 1px solid $tertiary-grey;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
  margin-bottom: 10px;
  margin-left: 5px;
  border-radius: 5px;
  background-color: #FFFFFF;
  span > * {
    margin-left: 10px;
  }
}
