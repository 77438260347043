@import "../../../styles/main.scss";

// .bullet_container {
//   display: flex;
//   padding: 10px;
//   max-height: 12%;
//   background-color: white;
//   border-radius: 8px;
//  // border: 1px solid lightgrey;
//  border-bottom: 1px solid lightgray;
//   margin-bottom: 10px;
//   position: relative;
//   align-items: center;
//   width: 70%;
//   margin: 10px auto;
  
//   // .profile_image {
//   //   border-radius: 100%;
//   //   width: 60px;
//   //   height: 60px;
//   // }

//   .bullet-text_container {
//     text-align: center;
//     display: inline-flex;
//     position: relative;
//     align-items: center;
//     width: 65%;
//   }

//   .actions {
//     display: flex;
//     flex-direction: column;
//     position: absolute;
//     width: 30%;
//     right: 10px;
//     text-align: center;
//   }
// }

@media (max-width: 370px) {
  .profile_image {
    display: none;
  }
}
