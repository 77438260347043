@import "../../../styles/main.scss";

.dropdown-result {
  cursor: pointer;
  height: 50px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  .image-area {
    flex: 2;
    img {
      width: 50px;
      border-radius: 100%;
    }
  }
  .text-area {
    padding: 0 10px;
    flex: 8;
  }
}
.dropdown-result:hover {
  background-color: $secondary-grey;
}
