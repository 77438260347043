@import "../../../styles/main.scss";

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100000;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
}

h2{
  text-align: center;
}
.modal-container .modal {
  z-index: 1;
  width: 350px;
  height: 200px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: relative;
  animation: animate 0.3s;
}
.modal button {
  position: absolute;
  top: 7px;
  right: 7px;
  padding: 8px 11px;
  background: crimson;
  color: white;
  font-weight: bold;
  border: none;
  outline: none;
  border-radius: 2px;
  cursor: pointer;
}

.modal .button-save {
  position: absolute;
  bottom: 10%;
  height: 25px;
  background: white;
  color: black;
  text-align: center;
  top: auto;
  width: 100px;
  left: 15%;
  padding: 0px;
  font-weight: bold;
  border: 1px solid gray;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal .button-dont-save {
  position: absolute;
  bottom: 10%;
  height: 25px;
  background: white;
  color: black;
  text-align: center;
  top: auto;
  width: 100px;
  right: 15%;
  left: auto;
  padding: 0px;
  font-weight: bold;
  border: 1px solid gray;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

@keyframes animate {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}