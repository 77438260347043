@import "../../../styles/main.scss";

.side-msg-desktop__organism-profile-card {
  margin-top: 10%;
  margin-bottom: 5%;
}
.side-msg-desktop__organism {
  position: absolute;
    width: 80px;
    padding: 0px !important;
    background-color: #FFFFFF;
    box-shadow: 9px 0px 42px -18px #f6f6f8;
    height: 100%;
    //border-left: 1px solid lightgrey;
    border-radius: 0px 20px 20px 0px;
    overflow-x: hidden;
    overflow-y: scroll;
  .logo {
    padding-top: 10%;
    display: flex;
    justify-content: center;
    img {
      width: 40%;
      cursor: pointer;
    }
  }
}::-webkit-scrollbar{
  display: none;
}
.side-msg-desktop__organism-msg-menu {
  margin-top: 20%;
}

.side-bar-mobile__organism {
  height: 50px;
  padding: 0 20px;
  display: flex;
  background-color: $secondary-grey;
  align-items: center;
  justify-content: space-between;
  position: relative;
  div {
    display: flex;
    align-items: center;
    .icon {
      margin-left: 20px;
    }
  }
}

.side-bar-mobile__organism-username {
  text-transform: capitalize;
}

.side-bar-mobile__organism-msg-menu {
  position: absolute;
  background: $primary-white;
  width: 100%;
  z-index: 99999;
  @include box-shadow;
}

.side-bar-mobile__organism-msg-menu-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99998;
}
