@import "../../../styles/main.scss";
.msg-slide{
  height: 100%;
    bottom: 0px;
  left: 10px;
  text-align: center;
  position: absolute;
  .msg-slide__molecule {
    display: flex;
    position: relative;
    margin-top: 10px;
    flex-direction: column;
    cursor: pointer;
    border-radius: 4px;
    .msg-slide-active__molecule-house-image {
      width: 55px;
      height: 55px;
      /* padding-bottom: 3px; */
      margin: auto;
      margin-top: 0px;
      border-radius: 200px;
      border: 1px solid #1a76d2;
    }
    .msg-slide__molecule-text {
      height: fit-content;
      text-align: center;
      .msg-slide__molecule-name {
        color: #000000;
        font-weight: 800;
      }
      .msg-slide__molecule-details {
        margin-bottom: 3px;
      }
    }

    &:hover {
      @include box-shadow;
    }
  }

  .msg-slide-active{
    top: 30px;
   // border-bottom: 1px solid lightgray;
    .msg-slide-active-btn{
      //position: relative;
      background-color: white;
      border-radius: 200px;
      border: 1px solid lightblue;
      margin: 10px 20px 5px 20px;
      top: 80px;
      height: 40px;
      width: 40px;
    }
  
  }
  .msg-slide-btn-text{
    margin: auto;
    overflow: clip;
    display: block;
    width: 60px;

  }
  // .msg-slide-border{
  //   background-color: #7d7f8014;
  //   /* border-bottom: 5px solid #1a76d2; */
  //   border-bottom: 2px solid #7d7f8014;
  //   // margin-top: 20px;
  //   margin: 10px auto 10px auto;
  // }
  .msg-slide-btn{
    background-color: rgb(227, 238, 242);
    border: 1px solid lightblue;
    border-radius: 200px;
    margin: auto;
    margin-top: 10px;
    margin-left: 22.5px;
    height: 35px;
    width: 35px;
  }
}

.msg-slide__window {
  //visibility: hidden;
  //visibility: visible;
  background-color: #FFFFFF;
 position: fixed;
  z-index: 10000;
  bottom: 0px;
  right: 85px;
  width: 330px;
  height: 335px;
  border: 1px solid lightgrey;
  border-radius: 10px;
  //flex-direction: column;
  //cursor: pointer;
  .msg-slide__window-textbar {
    //display: flex;
    background-color: #FFFFFF;
    position: absolute;
    //z-index: 10000;
    bottom: 0px;
    //right: 75px;
    left: 0px;
    width: 326px;
    height: 30px;
    border: 1px solid lightgrey;
    border-radius: 0px 0px 10px 10px;
    margin: 0;
    line-height: 1.35;
    font-size: 155%;
  }
  .msg-slide__window-msgs {
    background-color: #FFFFFF;
    /* top: 0px; */
    width: 330px;
    position: absolute;
    height: 296px;
    /* border: 1px solid lightgrey; */
    border-radius: 10px 10px 0px 0px;
    overflow: scroll;
    display: flex;
    flex-direction: column-reverse;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  //   .msg-slide-card-left__window-msgs{
  //     display:grid;
  //     margin-left: 5px;
  //     margin-top: 5px;
      
  //     .msg-slide-msgs__molecule-house-image{
  //       //position: relative;
  //       width: 20px;
  //       height: 20px;
  //       left: 0;
  //       border-radius: 20px;
    
  //     }
  //   }
  //   .msg-slide-card-right__window-msgs{
  //     position: absolute;
  //     display: grid;
  //     right: 8px;
  //     margin-top: 5px;
      
  //     .msg-slide-msgs__molecule-house-image{
  //       //position: relative;
  //       width: 20px;
  //       height: 20px;
  //       border-radius: 20px;
  //       display: block;
  //       margin-left: auto;
  //     }
  //   }
   }::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
    //flex-direction: column;
    //cursor: pointer;
}