@import "../../../styles/main.scss";

.site-list__molecule {
  display: flex;
  margin: 5px;
  flex-direction: row;
  border-radius: 4px;
  
  .card {
     width: 65px;
  }

  .site-list__molecule-house-image {
    width: 65px;
    height: 45px;
    border-radius: 4px;
    object-fit: cover;
  }

  .site-list__molecule-text {
    padding: 8px;
    font-size: 2em;
    .site-list__molecule-name {
      margin-bottom: 5px;
      width: 200px;
      text-align: center;
    }
    .site-list__molecule-details {
      margin-bottom: 3px;
    }
  }
  &:hover {
    @include box-shadow;
  } 
}

