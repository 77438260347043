@import "../../../../../../../../../styles/main.scss";

.sites__page {
  .map {
    height: 300px;
    width: 100%;
  }

  .object-slide {
    display: flex;
    margin: 1em 0;
    flex-wrap: wrap;
    .card {
      width: 200px;
    }
  }
}

.object-slide-container{
  position: absolute;
  top: 30%;
  background: #FFFFFF;
  border: 1px solid grey;
  border-radius: 13px;
  width: 50px;
  right: 90px;
  max-height: 305px;
  z-index: 1000;
  flex-wrap: wrap;
  overflow: hidden;
  &:hover{
    @include box-shadow;
  }
}

.object-slide {
  position: relative;
  // max-height: 235px; 
  height: 55px;
  width: 110%;
  z-index: 1000;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: auto;
  .card {
    width: auto;
  }
}
.buttons{
  position: absolute;
  top: 20px;
  left: 600px;
  z-index: 100000;
}

.site-list-container{
  position: absolute;
  border-radius: 13px;
  background: #FFF;
  border: 1px solid dimgrey;
  top: 25px;
  width: 300px;
  left: 320px;
  height: 55px;
  z-index: 1000;
  flex-wrap: wrap;
  overflow: hidden;
    &:hover{
      @include box-shadow;
    }
}

.site-list {
    width: 110%;
    height: 100%;
    overflow: auto;
    padding-right: 0px;
}

@media (max-width: $mobile) {
  .sites__page {
    .object-slide {
      flex-direction: column;
      .card {
        width: inherit;
      }
    }
  }
}
