@import "../../../../../../../../styles/main.scss";

//styling for Adding FloorPlan Name
.container_floorplan-name {
  margin-top: 30px;
  text-align: center;
  .create-floorplan__input-name {
    text-align: left;
    width: 50%;
    margin: auto;
  }
  .create-floorplan__button {
    margin-right: 10px;
  }
}
