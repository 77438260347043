@import "../../../styles/main.scss";

.sign-in__page {
  display: flex;
  height: 100vh;
  .app-version_left-area {
    align-self: flex-end;
    padding: 5px;
  }
  .left-area {
    flex: 6;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .sign-up-option {
      position: absolute;
      top: 50px;
      right: 50px;
    }
    .logo {
      position: absolute;
      top: 50px;
      left: 50px;
    }
    .sign-in-form {
      min-width: 50%;
      .title {
        margin-bottom: 50px;
      }
      .forgot-password {
        margin-bottom: 40px;
      }
    }

    i {
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
      &:hover {
        color: $primary-light-blue;
        cursor: pointer;
      }
    }
  }
  .right-area {
    background-image: url(https://images.pexels.com/photos/2462015/pexels-photo-2462015.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260);
    background-size: cover;
    flex: 4;
    background-color: $secondary-grey;
    display: flex;
    // align-items: center;
    h1 {
      width: 40%;
      margin-left: 10%;
      margin-top: 10%;
      font-weight: $font-weight-semi-bold;
    }
  }
}

@media (max-width: $mobile) {
  .sign-in__page {
    .left-area {
      .sign-in-form {
        width: 80%;
      }
    }
    .right-area {
      display: none;
    }
  }
}
