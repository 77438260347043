@import "../../../styles/main.scss";

.sites-section_profile-related-display {
  border: 1px solid $tertiary-grey;
  background: $secondary-grey;
  border-radius: 4px;
  margin-top: 10px;
  padding: 10px;
  display: flex;
}
.devices-section_profile-related-display {
  border: 1px solid $tertiary-grey;
  background: $secondary-grey;
  border-radius: 4px;
  margin-top: 10px;
  padding: 10px;
  display: flex;
}
