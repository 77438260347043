@import "../../../../../styles/main.scss";

// .bullet_text {
//   padding: 4px;
//   font-size: 1.5em;
//   margin: auto;
//   display: flex;
//   .timestamp_bullet-text {
//     color: $primary-light-blue;
//   }
// }
// .action-buttons_container {
//   padding: 4px;
//   .action-button {
//     margin: 2px;
//   }
// }

// .bullet_container-delete-container {
//   position: relative;
//   right: 0;
//   width: 20px;
//   height: fit-content;
//   margin-left: 10px;
//   display: flex;
//   flex-direction: column;
// }



.bullet_container {
  position: relative;
  .profile_image {
    width: 30px;
    border-radius: 100%;
    height: 30px;
    top: 20px;
    position: absolute;
  }

  .bullet-text_container{
    position: absolute;
    left: 40px;

    text-align: center;
    display: inline-flex;
    // position: relative;
    align-items: center;
    width: 80%;

    text-align: center;
    display: inline-flex;
    position: relative;
    align-items: center;
    // width: 65%;
    .bullet_text {
      padding: 4px;
      font-size: 1.5em;
      margin: auto;
      display: flex;
    }

    .timestamp_bullet-text {
      color: $primary-light-blue;
    }

    .action-buttons_container {
      padding: 4px;
      .action-button {
        margin: 2px;
      }
    }
  }

  .bullet_container-delete-container {
    position: absolute;
    right: 0;
    width: 20px;
    // height: fit-content;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    // .X-container {
    //   .bullet_container-delete_button-container {
    //     // position: relative;
    //     // right: 0;
    //     // width: 20px;
    //     // height: fit-content;
    //     // margin-left: 10px;
    //     // display: flex;
    //     // flex-direction: column;
    //   }
    // }
    // .tooltip {
      
    // }
  }
}