@import "../../../styles/main.scss";

@mixin button($primary-color, $secondary-color: $primary-white) {
  background-color: $primary-color;
  color: $secondary-color;
  border: 1px solid $primary-color;
  padding: 4px 12px;
  border-radius: 4px;

  &:hover {
    background-color: $secondary-color;
    color: $primary-color;
    border: 1px solid $primary-color;
  }
}

@mixin button-without-hover($primary-color, $secondary-color: $primary-white) {
  background-color: $primary-color;
  color: $secondary-color;
  border: 1px solid $primary-color;
  padding: 4px 12px;
}

@mixin button-outlined($primary-color, $secondary-color: $primary-white) {
  color: #000000;
  border: 1px solid $primary-color;
  padding: 4px 12px;
  background-color: $secondary-color;

  &:hover {
    background-color: $primary-dark-blue;
    color: $secondary-color;
    border: 1px solid $primary-color;
  }
}

@mixin button-outlined-without-hover(
  $primary-color,
  $secondary-color: $primary-white
) {
  color: $primary-color;
  border: 1px solid $primary-color;
  padding: 4px 12px;
  background-color: $secondary-color;
}

.button-atom__ {
  cursor: pointer;
  transition-duration: 0.3s;
  border-radius: 4px;
}

.button-atom__primary {
  @include button($primary-dark-blue);
}
.button-atom__primary-right {
  float: right;
  @include button($primary-dark-blue);
}

.button-atom__primary-outlined {
  @include button-outlined($primary-white);
}

.button-atom__success {
  @include button($primary-green);
}

.button-atom__success-outlined {
  @include button-outlined($primary-green);
}

.button-atom__danger {
  @include button($primary-red);
}

.button-atom__danger-outlined {
  @include button-outlined($primary-red);
}

.button-atom__small {
  font-size: $font-s;
}

.button-atom__medium {
  font-size: $font-m;
}

.button-atom__large {
  font-size: $font-l;
  padding: 10px 20px;
}

.button-atom__disabled {
  @include button-without-hover($primary-grey);
  cursor: not-allowed;
}
.button-atom__disabled-outlined {
  @include button-outlined-without-hover($primary-grey);
  cursor: not-allowed;
}

.button-atom__with-icon {
  display: flex;
  align-items: center;
  & > svg {
    margin-right: 5px;
  }
}
