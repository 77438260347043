@import "./styles/main.scss";

body {
  margin: 0;
  font-family: $primary-font;
}
h1,
h2,
h3,
h4,
h5,
h6,
pre,
p {
  margin: 0;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}
