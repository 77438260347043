@import "../../../styles/main.scss";

.link-atom__ {
  transition-duration: 0.3s;
  color: $primary-dark-blue;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: $primary-teal;
  }
}
