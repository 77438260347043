$primary-dark-blue: rgb(26, 118, 210);
$primary-light-blue: rgb(101, 148, 196);

$primary-teal: rgb(48, 211, 186);
$secondary-teal: #77e6d5;

$primary-grey: #666;
$secondary-grey: #f6f6f8;
$tertiary-grey: #d7d7d7;

$primary-black: #242424;
$secondary-black: #434343;

$primary-white: #ffffff;

$primary-red: #ff3939;

$primary-green: #4bb543;
