@import "../../../styles/main.scss";

.text__atom {
  color: $secondary-black;
}

.text__atom-regular {
  font-size: $font-m;
}

.text__atom-disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.text__atom-small {
  font-size: $font-s;
}

.text__atom-large {
  font-size: $font-l;
}

.text__atom-extra-small {
  font-size: $font-xs;
}

.text__atom-extra-large {
  font-size: $font-xl;
}
.text__atom-extra-extra-large {
  font-size: $font-xxl;
}

.text__atom-page-heading {
  font-size: $font-xxl;
  font-weight: $font-weight-light;
  color: $primary-black;
}

.text__atom-interior-page-heading {
  font-size: $font-xxl;
  font-weight: $font-weight-light;
  background: white;
  color: $primary-black;
  border-bottom: 1px solid $tertiary-grey;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.text__atom-interior-page-sub-heading {
  font-size: $font-xl;
  font-weight: $font-weight-light;
  color: $primary-black;
  margin-bottom: 10px;
}

.text__atom-heading-one {
  font-size: $font-xxl;
  font-weight: $font-weight-light;
  color: $primary-black;
}
