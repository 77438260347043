@import "../../../styles/main.scss";

.user-cards__Owner {
  background-color: $secondary-grey;
}

.user-cards__Pending {
  background-color: $secondary-grey;
}

.user_cards {
  cursor: pointer;
  border: 1px solid $tertiary-grey;
  border-radius: 4px;
  text-align: center;
  width: 150px;
  padding: 20px 10px;
  margin: 10px;
  .user-card__molecule-profile-image {
    width: 70%;
    border-radius: 50%;
  }
  .user-card__molecule-text {
    padding-top: 10px;
    .user-card__molecule-element {
      margin-bottom: 5px;
    }
  }
}

@media (max-width: $mobile) {
  .user_cards {
    width: inherit;
    margin-right: 0;
    margin-bottom: 10px;
  }
}
