@font-face {
  font-family: "OpenSans";
  font-weight: 800;
  src: local("OpenSans"),
    url(../assets/fonts/open-sans/OpenSans-ExtraBold.ttf) format("truetype");
}
@font-face {
  font-family: "OpenSans";
  font-weight: 700;
  src: local("OpenSans"),
    url(../assets/fonts/open-sans/OpenSans-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "OpenSans";
  font-weight: 600;
  src: local("OpenSans"),
    url(../assets/fonts/open-sans/OpenSans-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans";
  font-weight: 400;
  src: local("OpenSans"),
    url(../assets/fonts/open-sans/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans";
  font-weight: 300;
  src: local("OpenSans"),
    url(../assets/fonts/open-sans/OpenSans-Light.ttf) format("truetype");
}

$primary-font: "OpenSans", sans-serif;

$font-xs: 0.4em;
$font-s: 0.625em;
$font-m: 0.8em;
$font-l: 1.125em;
$font-xl: 1.5em;
$font-xxl: 2em;

$font-weight-extra-bold: 800;
$font-weight-bold: 700;
$font-weight-semi-bold: 600;
$font-weight-regular: 400;
$font-weight-light: 300;
