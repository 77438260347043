@import "../../../../styles/main.scss";

.account__page {
  .account-details {
    width: 70%;
    margin: auto;
  }
  .edit-email {
    width: 100%;
    padding: 0 5px;
    button {
      margin: 0 5px;
    }
  }
}
.account_page-profile_pic {
  border-radius: 50%;
  width: 15%;
}

@media (max-width: $mobile) {
  .account__page {
    .account-details {
      width: 90%;
    }
  }
}
