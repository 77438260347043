@import "../../../styles/main.scss";

@mixin active-item {
  color: #FFFFFF;
  border-bottom: 3px solid $primary-dark-blue;
}

.map-nav {
  top: 5px;
  right: 100px;
  z-index: 1000;
  position: absolute;
  border: 2px solid white;
  border-radius: 40px;
  max-width: 1170px;
  margin: auto;
  /* background: wheat; */
  background: #116CD2;
  padding: 5px 0;
  overflow: hidden;
  .map-nav-item {
    transition-duration: 0s;
    color: #FFFFFF;
    text-decoration: none;
    margin-right: 5px;
    margin-left: 5px;
    &:hover {
      @include active-item;
    }
  }
  .map-nav-item-is-active {
    @include active-item;
  }
}
