@import "../../../../../../../styles/main.scss";

@mixin active-item {
  color: $primary-dark-blue;
  background-color: #FFFFFF;
  border: 1px solid $primary-dark-blue;
}

.site-summary__component-summary-page {
  position: absolute;
  height: 90%;
  width: 78%;
  left: 15%;
  .site-meta-data {
    display: flex;
    border: 2px solid black;
    width: 35%;
    position: relative;
    border-radius: 20px;
    background: white;
    margin-bottom: 15px;
    margin-left: 12%;
    margin-top: 20px;
    .site-basic-details {
      height: 100%;
      width: 30%;
      margin: 10px 0 10px 0;
      border-bottom: 1px solid lightgray;
    }
    
    .house-image {
      height: 100px;
    width: 100px;
    margin: 15px;
    border-radius: 20px;
    /* margin-right: 30px; */
    /* margin-top: 20px; */
    background-size: contain
    }
  }
  .map-container{
    float: left;
    height: 80%;
    width: 60%;
    margin-right: 10px;
    border-radius: 20px;
    border: 2px solid lightgrey;
    position: relative;
    .map-buttons{
      right: 20px;
      z-index: 1000;
      top: 20px;
      position: absolute;
      .map-site-nav-item-out{
        background-color: $primary-dark-blue;
        color: white;
        border: 1px solid lightgray;
        padding: 3px;
        border-radius: 8px 0px 0px 8px;
        &:hover {
          @include active-item;
        }
      }
      .map-site-nav-item-in{
        background-color: $primary-dark-blue;
        color: white;
        border: 1px solid lightgray;
        padding: 3px;
        border-radius: 0px 8px 8px 0px;
        &:hover {
          @include active-item;
        }
      }
      .map-site-nav-item-out-active{
        background-color: $primary-dark-blue;
        color: white;
        border: 1px solid lightgray;
        padding: 3px;
        border-radius: 8px 0px 0px 8px;
      }
      .map-site-nav-item-in-active{
        background-color: $primary-dark-blue;
        color: white;
        border: 1px solid lightgray;
        padding: 3px;
        border-radius: 0px 8px 8px 0px;
        &:hover {
          @include active-item;
        }
      }
      .map-nav-item-is-active {
        @include active-item;
      }
    }
  }

  .site-profile-header-background {
    position: absolute;
    height: 150px;
    /* z-index: 2; */
    width: 50%;
    left: 5%;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: flex-end; */
    background: url(/static/media/1622849321438.f18185fc.jpg), #c4c4c4;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 30px;
  }

  .map {
    display: flex;
    //top: 150px;
    //float: left;
    position: absolute;
    margin: 0px;
    height: 100%;
    width: 100%;
   // margin-right: 10px;
  }

  .site-feed-container{
    position: relative;
    background-color: white;
    height: 95%;
    top: -15%;
    border: 2px solid lightgray;
    width: 35%;
    border-radius: 20px;
    right: 1%;
    padding: 0px;
    float: right;
    .site-feed{
      margin: 10px;
      text-align: center;
    }
  }
  
  .display-users {
    margin: 20px 0;
  }
}

@media (max-width: $mobile) {
  .site-summary__component-summary-page {
    .site-meta-data {
      display: block;

      .map,
      .house-image {
        height: 300px;
        width: 100%;
        padding-right: 0px;
        margin-bottom: 10px;
      }
    }
  }
}
