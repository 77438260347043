@import "../../../styles/main.scss";
.members__page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .add-member {
    text-align: center;
    color: $primary-grey;
    padding: 20px;
    margin: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    width: 200px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .add-member-icon {
      margin-bottom: 10px;
      font-size: 50px;
    }
  }
}

