@import "../../../styles/main.scss";
// case
.summary-card {
  padding-top: 10px;
  border-radius: 10px;
  margin-left: 10px;
  .summary-element {
    margin-bottom: 10px;
  }
}
