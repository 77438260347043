@import "../../../styles/main.scss";

.comment-card__molecule {
  margin: 10px;
  margin-bottom: 40px;
  height: fit-content;
  width: 90%;
  background-color: white;
  position: relative;
  left: 10px;
  display: flex;
  flex-direction: column;

  .comment-profile-primary-container{
    display: flex;
    flex-direction: row;

    .comment-profile-picture {
      height: 40px;
      width: 40px;
      border-radius: 20px;
    }
    
    .comment-profile-body-container{
      padding: 2px;
      
      // .post-profile-picture:hover{
      //   cursor: pointer;
      //   border: none !important;
      //   box-shadow: none m !important;
      //   // border: 3px solid #116cd2;
      // }
      .comment-profile-body {
        display: flex;
        margin-left: 10px;
        height: -moz-fit-content;
        height: fit-content;
        width: 350px;
        border-radius: 10px;
      }

      .comment-profile-date {
        margin-left: 10px;
        font-size: 12px;
        // gap: 100px
        white-space: nowrap; /* Prevent line breaks */
        gap: 10px; /* Add 10px of space between elements */
        display: flex; /* Use flexbox to align items */
        align-items: center; /* Vertically align items */
      }

      .icon {
        color: $primary-red;
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }
    }
  }

  .comment-profile-count-container{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    border-bottom: 1px solid #C4C4C460;

    .comment-profile-comment{
      margin-left: 10px;
      font-size: 14px;
    }

    .comment-profile-comment-count{
      margin-left: 10px;
      font-size: 14px;
    }

    .comment-profile-like{
      margin-left: 10px;
      font-size: 14px;
    }

    .comment-profile-like-count{
      margin-left: 10px;
      font-size: 14px;
    }
  }

  .comment-profile-btn-container{
    padding: 0px;

    .comment-profile-comment-btn{
      width: 50%;
      background-color: $primary-white;
      border: none;
      padding: 3px;
    }:hover {
        // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0px 0px 13px -9px rgba(0, 0, 0, 1);
        -moz-box-shadow: 0px 0px 13px -9px rgba(0, 0, 0, 1);
        box-shadow: 0px 0px 13px -9px rgba(0, 0, 0, 1);
    }

    .comment-profile-like-btn{
      width: 50%;
      background-color: $primary-white;
      border: none;
      padding: 3px;
      position: absolute;
      right: 0px;
    }:hover {
        // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0px 0px 13px -9px rgba(0, 0, 0, 1);
        -moz-box-shadow: 0px 0px 13px -9px rgba(0, 0, 0, 1);
        box-shadow: 0px 0px 13px -9px rgba(0, 0, 0, 1);
      }
  }


  // .postcard-comment {
  //   visibility: visible;
  // }
  // .postcard-comment-noshow{
  //   visibility: collapse;
  // }
}

.inactive-comment {
  background-color: $secondary-grey;
  cursor: not-allowed;
}
