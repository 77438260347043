@import "../../../styles/main.scss";

@mixin active-item {
  color: $primary-dark-blue;
  border-bottom: 3px solid $primary-dark-blue;
  background-color: $primary-dark-blue !important;
}

.sub-menu {
 // padding: 10px 0;
  overflow: auto;
  margin-left: 5% !important;
  .sub-menu-item {
    transition-duration: 0s;
    border: 1px solid #FFFFFF;
    color: $primary-grey;
    text-decoration: none;
    margin-right: 10px;
  }
}
