@import "../../../styles/main.scss";

.site_device_card {
  cursor: pointer;
  border: 1px solid $tertiary-grey;
  border-radius: 4px;
  text-align: center;
  width: 150px;
  margin: 10px;
  
  .site-device-card_icon {
    width: 100%;
    height: 50px;
    padding: 20px 0;
    background: $primary-grey;
    color: $primary-white;
  }
  .site-device-card_display-texts {
    padding: 10px 5px 0px 5px;
    .site-device-card__molecule-text-element_name {
      padding-bottom: 5px;
    }
    .site-device-card__molecule-text-element {
      text-align: left;
      margin-bottom: 5px;
    }
  }
}
