@import "../../../../styles/main.scss";

.devices-page {
  height: 100%;
  border: $primary-dark-blue solid 3px;
  padding: 10px 20px;

  .loading__atom{
    margin: 0 auto;
  } 
}
.dev-section {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 20px 20px;
  justify-content: center;

  .site_device_card {
    box-shadow: 0px 2px 8px -3px rgba(0, 0, 0, 0.6);
  }

  .site_device_card .site-device-card_icon {
    background: linear-gradient(149.45deg, #62a4db 7%, #63a8da 19.4%, #6addcc 95.05%);
  }
}
