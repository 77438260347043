@import "../../../../styles/main.scss";
.text-checkbox__atom {
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .label {
    margin: 5px 0;
    display: inline;
    text-align: center;
  }
  input {
    width: 40px;
    height: 20px;
    box-sizing: border-box;
    padding-top: 4px;
    padding-right: 8px;
    padding-bottom: 4px;
    padding-left: 8px;
    background-color: $secondary-grey;
    outline: none;
    border: 1px solid $tertiary-grey;
    border-radius: 4px;
    margin-bottom: 10px;
  }

  input[type="checkbox"] {
    cursor: pointer;
    position: relative;
    -webkit-appearance: none;
    background: $secondary-grey;
    outline: none;
    border-radius: 20px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    transition: 0.5s;
  }
  input:checked[type="checkbox"] {
    background: $secondary-teal;
  }
  input[type="checkbox"]:before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: -2px;
    left: 0;
    background: $primary-white;
    border: 1px solid $primary-grey;
    // transform: scale(1.1);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: 0.5s;
  }
  input:checked[type="checkbox"]:before {
    left: 15px;
    background: $primary-white;
  }
  .error {
    margin-top: -8px;
  }
}
