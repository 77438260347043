@import "../../../styles/main.scss";

.side-bar-desktop__organism-profile-card {
  margin-top: 10%;
  margin-bottom: 5%;
}
.side-bar-desktop__organism {
  position: relative;
  width: 200px;
  padding: 0 0px;
  background-color: #FFFFFF;
  box-shadow: 9px 0px 42px -18px $secondary-grey;
  min-height: 100%;
  border-radius: 0px 20px 20px 0px;
  // border-right: 1px solid lightgrey;
  .logo {
    padding-top: 10%;
    display: flex;
    left: 20px;
    /* justify-content: center; */
    position: relative;
    img {
      width: 50%;
      height: 45px;
      cursor: pointer;
    }
  }
}
.side-bar-desktop__organism-nav-menu {
  margin-top: 10%;
  border-top: 2px solid #7d7f8014;
  padding-top: 10px;
}

.side-bar-mobile__organism {
  height: 50px;
  padding: 0 20px;
  display: flex;
  background-color: $secondary-grey;
  align-items: center;
  justify-content: space-between;
  position: relative;
  div {
    display: flex;
    align-items: center;
    .icon {
      margin-left: 20px;
    }
  }
}

.side-bar-mobile__organism-username {
  text-transform: capitalize;
}

.side-bar-mobile__organism-nav-menu {
  position: absolute;
  background: $primary-white;
  width: 100%;
  z-index: 99999;
  @include box-shadow;
}

.side-bar-mobile__organism-nav-menu-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99998;
}
