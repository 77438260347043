@import "../../../../../../../../styles/main.scss";

.container_display-floorplan {
  display: flex;
  position: relative;
  width: 50%;
  .sidepanel_display-floorplan {
    width: 10%;
    margin: 0 5px;
    margin-left: 20px;
    .names-wrapper_sidepanel_display-floorplan {
      margin-top: 5px;
      .names_sidepanel_display-floorplan {
        cursor: pointer;
        margin-top: 10px;
        padding: 5px;
        text-align: center;
      }
      .names_sidepanel_display-floorplan:hover {
        background-color: $tertiary-grey;
        border-radius: 4px;
      }
    }
  }
  .display-area_display-floorplan {
    margin-top: 2%;
    margin-left: 7%;
  }

}
