@import "../../../styles/main.scss";

.sign-up__page {
  display: flex;
  height: 100vh;

  .left-area {
    flex: 6;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $secondary-grey;

    .sign-up-option {
      position: absolute;
      top: 50px;
      right: 50px;
    }

    .logo {
      position: absolute;
      top: 50px;
      left: 50px;
    }

    .register-success {
      display: flex;
      flex-direction: column;
      min-width: 60%;
      min-height: 50%;
      text-align: center;
      align-items: center;
      justify-content: center;
      background-color: $primary-white;
      border-radius: 4px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      padding: 0 10px;
      .title {
        margin-bottom: 50px;
      }
      .message-text-register-success {
        color: $primary-teal;
        margin-bottom: 10px;
      }
    }
  }
  .right-area {
    background-image: url(https://images.pexels.com/photos/2462015/pexels-photo-2462015.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260);
    background-size: cover;
    flex: 4;
    background-color: $secondary-grey;
    display: flex;
    h1 {
      width: 40%;
      margin-left: 10%;
      margin-top: 10%;
      font-weight: $font-weight-semi-bold;
    }
  }
}

@media (max-width: $mobile) {
  .sign-up__page {
    .left-area {
      .register-success {
        width: 80%;
      }
    }
    .right-area {
      display: none;
    }
  }
}
