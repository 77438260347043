@import "../../../styles/main.scss";

.object-slide__molecule {
  display: flex;
  margin-top: 8px;
  flex-direction: row;
  cursor: pointer;
  border-radius: 4px;
  .object-slide__molecule-house-image {
    width: 55px;
    height: 40px;
    padding-bottom: 3px;
    // margin: auto;
    margin-left: 10px;
  }
  .object-slide__molecule-text {
    height: fit-content;
    width: 55%;
    // height: 40px;
    line-height: 40px;
    text-align: center;
    margin-left: 10px;
    // font-size: 30px;
    // vertical-align: center;
    color: rgb(102, 102, 102);
    font-size: 2.0em;
    .object-slide__molecule-name {
      color: rgb(102, 102, 102);
      font-weight: 700;
    }
    .object-slide__molecule-details {
      margin-bottom: 3px;
    }
  }
  &:hover {
    @include box-shadow;
  }
}
