@import "../../../../../../styles/main.scss";

.sites__page-main {
  height: 100%;
  .map {
    height: 300px;
    width: 97%;
  }

  .location-cards {
    display: flex;
    height: 50%;
    flex-wrap: wrap;
    position: absolute;
    overflow: scroll;
    margin-left: 5%;
    margin-right: 5%;
    justify-content: center;
    width: 90%;
    .card {
      width: 200px;
      height: 200px;
    }
  }::-webkit-scrollbar-track {
    background: white;
  }::-webkit-scrollbar-thumb {
    background: white;
}
}

@media (max-width: $mobile) {
  .sites__page {
    .location-cards {
      flex-direction: column;
      .card {
        width: inherit;
      }
    }
  }
}
