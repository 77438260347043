@import "../../../styles/main.scss";

.profile-card__molecule-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-card__molecule-image {
  border-radius: 50%;
  width: 30%;
}

.profile-card__molecule-name {
  margin-top: 5%;
  text-transform: capitalize;
}
