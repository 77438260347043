@import "../../../styles/main.scss";
.msg-slide{
  bottom: 8px;
    height: 100%;
    /* overflow: scroll; */
    /* left: 10px; */
    /* text-align: center; */
    position: absolute;
  .msg-slide__molecule {
    display: flex;
    position: relative;
    margin-top: 10px;
    margin-bottom: 15px;
    flex-direction: column;
    cursor: pointer;
    border-radius: 4px;
    .msg-slide__molecule-house-image {
      width: 55px;
      height: 55px;
      // padding-bottom: 3px;
      margin: auto;
      // margin-top: 10px;
      border-radius: 200px;
    }
    .msg-slide__molecule-text {
      height: fit-content;
      text-align: center;
      .msg-slide__molecule-name {
        color: #000000;
        font-weight: 800;
      }
      .msg-slide__molecule-details {
        margin-bottom: 3px;
      }
    }

    &:hover {
      @include box-shadow;
    }
  }

  .msg-slide-active{
    top: 30px;
   // border-bottom: 1px solid lightgray;
    .msg-slide-active-btn{
      //position: relative;
      background-color: white;
      border-radius: 200px;
      border: 1px solid lightblue;
      margin: 10px 20px 5px 20px;
      top: 80px;
      height: 40px;
      width: 40px;
    }
  
  }
  .msg-slide-btn-text{
    margin: auto;
    overflow: clip;
    display: block;
    width: 60px;
    font-size: xx-small;

  }
  .msg-slide-border{
    background-color: #7d7f8014;
    /* border-bottom: 5px solid #1a76d2; */
    border-bottom: 1px solid #7d7f806b;
    // margin-top: 20px;
    margin: 15px auto 15px auto;
  }
  .msg-slide-btn{
    background-color: rgb(227, 238, 242);
    border: 1px solid lightblue;
    border-radius: 200px;
    margin: auto;
    margin-top: 10px;
    margin-left: 22.5px;
    height: 35px;
    width: 35px;
  }
}