@import "../../../../../styles/main.scss";

.bullet_text {
  padding: 4px;
}

.buttons-and-server-text {
  position: relative;
  .action-buttons_container {
    padding: 4px;
    .action-button {
      margin: 2px;
    }
  }
  .bullet-server-text {
    position: absolute;
    left: 5px;
    color: red;
  }
}
.bullet_container-delete-container {
  position: absolute;
  right: 0;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  // position: relative;
  .X-container {
    position: relative;
    .bullet_container-delete_button-container {
      width: 15px;
      padding: 5px;
      color: red;
      text-align: center;
      position: absolute;
      right: 0;
    }
    .bullet_container-delete_button-container:hover {
      background-color: $primary-white;
      color: red;
      cursor: pointer;
      border-radius: 4px;
    }
  }
  .tooltip {
    visibility: hidden; /* Or display: none, depending on how you want it to behave */
    margin-right: 0px;
    color: red;
    margin-top: 40px;
  }
  .X-container:hover + .tooltip {
    /* Uses the adjacent sibling selector */
    visibility: visible; /* Or display: block */
  }
}
