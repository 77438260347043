@import "../../../../../../styles/main.scss";

.devices__page-main {
  height: 100%;

  .device-container {
    width: 100%;
    height: 95%;
    left: 0px;
    position: relative;
    border-radius: 20px;
    background: white;
    border: 1px solid lightgrey;
  }
  
  .map {
    height: 300px;
    width: 97%;
  }

  .device-location-cards {
    display: flex;
    // height: 64%;
    height: fit-content;
    flex-wrap: wrap;
    position: absolute;
    overflow: scroll;
    margin-left: 5%;
    margin-right: 5%;
    justify-content: center;
    width: 90%;
    .card {
      width: 200px;
    }
  }::-webkit-scrollbar-track {
    background: white;
  }::-webkit-scrollbar-thumb {
    background: white;
}
}

@media (max-width: $mobile) {
  .devices__page {
    .device-location-cards {
      flex-direction: column;
      .card {
        width: inherit;
      }
    }
  }
}
