@import "../../../../../../../../styles/main.scss";

.editor-plane {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-top: 16px;
  .side-plane {
    height: 15%;
    text-align: center;
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    background-color: $secondary-grey;
    .side-plane_buttons {
      margin-right: 10px;
    }
    .save_cancel_buttons-floorplan_editor {
      display: flex;
    }
    .add_delete_buttons-floorplan_editor {
      display: flex;
    }
    .node_buttons-floorplan_editor {
      display: flex;
      .select_device-floorplan_editor {
        margin-right: 10px;
      }
    }
  }
  .stage {
    height: 85%;
  }
}
