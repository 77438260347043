@import "../../../../../../../styles/main.scss";

.create-accessgroup-button-div {
  display: flex;
  flex-flow: row-reverse;
  .create-accessgroup-button {
    margin-top: 15px;
    margin-right: 20px;
  }
}
.accesslevel-container {
  padding: 20px;
  .accesslevel_heading-container {
    display: flex;
    margin-bottom: 10px;
    border-bottom: 1px solid $tertiary-grey;
    position: relative;
    justify-content: space-between;
    .accessgroup-buttons {
      text-align: center;
      .accessgroup-button {
        margin-left: 10px;
        margin-bottom: 5px;
      }
    }
  }
  .user_cards-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .add-user {
      text-align: center;
      margin: 10px;
      color: $primary-grey;
      &:hover {
        color: $secondary-teal;
      }

      .add-user-icon {
        margin-bottom: 10px;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
}
///////// Search area styling
.search-bar_addUserAccessManagement {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .searchBarandResults_wrapper {
    position: relative;
    margin-right: 5px;
    flex: 1;
  }

  input:focus {
    background-color: $primary-white;
  }
  .search-results-dropdown {
    position: absolute;
    top: 48px;
    width: 100%;
    @include box-shadow;
    max-height: 300px;
    overflow: auto;
  }
}

// TO BE OPTIMIZED LATER

.permission-of {
  margin: 20px 0;
  display: flex;
  align-items: center;
  padding: 0 10px;
  background-color: $secondary-grey;
  border-radius: 4px;
  .permission-of-name {
    margin-right: 10px;
    flex: 2;
  }
  .permission-levels-group {
    flex: 8;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    .permission-levels {
      margin-right: 10px;
      padding: 2px;
      width: 60px;
    }
  }
}

.edit_group-update-button {
  margin-right: 10px;
}
//////////////////Create group styling
.create-access-group_heading {
  margin-top: 20px;
}

.create-access-group__input-name {
  margin-top: 20px;
  p {
    font-size: $font-l !important;
  }
}

.members__page-event {
  position: absolute;
    overflow-y: hidden;
    height: 90%;
    margin-right: 95px;
    width: -webkit-fill-available;
  .map {
    height: 300px;
    width: 97%;
  }

  .members-containerr{
    margin-bottom: 20px;
    overflow: hidden;
    .members-cards-container{
      display: flex;
      flex-wrap: wrap;
      margin-left: 10%;
      margin-right: 10%;
      width: auto;
      overflow: scroll;
    }
  }

  .members-cards-container{
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    // margin-left: 10%;
    // margin-right: auto;
    height: 65%;
    justify-content: center;
    width: 100%;
    overflow: scroll;
  }

  .location-cards {
    display: flex;
    height: 50%;
    flex-wrap: wrap;
    position: absolute;
    overflow: scroll;
    margin-left: 5%;
    margin-right: 5%;
    justify-content: center;
    width: 90%;
    .card {
      width: 200px;
      height: 200px;
    }
  }::-webkit-scrollbar-track {
    background: white;
  }::-webkit-scrollbar-thumb {
    background: white;
}
}


////////////////////////////////

@media (max-width: $mobile) {
  .user_cards-container {
    flex-direction: column;
  }
}
