.Toastify__toast--success {
  background-color: $primary-green;
}

.Toastify__toast {
  font-family: inherit;
  font-size: $font-m;
  min-height: auto;
  box-shadow: none;
}
