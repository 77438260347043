@import "../../../../../styles/main.scss";

.selected-user__component {
  background-color: $primary-white;
  padding: 10px;
  display: flex;
  border: 1px solid $primary-grey;
  align-items: center;

  .image-area {
    img {
      border-radius: 100%;
      height: 70px;
    }
  }
  .text-area {
    padding: 0 10px;
  }
}
