@import "../../../styles/main.scss";

.loading__atom {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .lds-ring {
    display: inline-block;
    position: relative;
  }
  .lds-ring span {
    box-sizing: border-box;
    display: block;
    position: absolute;
    border: 0.5em solid $primary-teal;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $primary-teal transparent transparent transparent;
  }
  .lds-ring.small {
    width: 40px;
    height: 40px;
  }
  .lds-ring.small span {
    width: 12px;
    height: 12px;
    border-width: 0.1em;
  }

  .lds-ring.medium {
    width: 80px;
    height: 80px;
  }
  .lds-ring.medium span {
    width: 64px;
    height: 64px;
    border-width: 0.5em;
  }

  .lds-ring.large {
    width: 160px;
    height: 160px;
  }
  .lds-ring.large span {
    width: 128px;
    height: 128px;
    border-width: 0.8em;
  }
  .lds-ring span:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring span:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring span:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
