@import "../../../styles/main.scss";

.feednot-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  visibility: collapse;
  z-index: 100000;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
}
.feednot-modal-container-show{
  position: fixed;
  top: 40px;
  left: 220px;
  bottom: 10px;
  width: 35%;
  border: 1px solid #C4C4C460;
  z-index: 100000;
  border-radius: 8px;
  height: 94.5%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  background: #ffffff;
  //overflow: hidden;
  .feednot-select-container{
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 15px;
    width: fit-content;
    height: fit-content;
    border-radius: 8px;
    .feednot-select-feed{
      width: 50%;
      min-width: fit-content;
      padding: 5px 15px 5px 15px;
      border-width: 0px 1px 0px 0px;
      background-color: $primary-dark-blue;
      color: $primary-white;
      border-radius: 8px 0px 0px 8px;
    }
    .feednot-select-not{
      width: 50%;
      min-width: fit-content;
      padding: 5px 15px 5px 15px;
      border-width: 0px 0px 0px 1px;
      background-color: $primary-dark-blue;
      color: $primary-white;
      border-radius: 0px 8px 8px 0px;
    }
  }


  .feed-post{
    position: relative;
    display: flex;
    width: 90%;
    background-color: $primary-white;
    top: 40px;
    z-index: 1000;
    height: fit-content;
    border-radius: 5px;
    border: 1px solid #C4C4C460;
    .feed-post-textarea{
      width: 100%;
      height: 90px;
      border-radius: 5px;
      resize: none;
    }
    .feed-post-btn{
      position: absolute;
      margin-top: 3px;
      height: 30px;
      width: 50px;
      top: 100px;
      right: 20px;
      background-color: $primary-dark-blue;
      color: $primary-white;
    }
  }

  .feed-post-noshow{
    visibility: hidden;
  }


  .feed-container{
    width: 90%;
    height: 75% !important;
    top: 220px;
    position: absolute;
    overflow: scroll;
    z-index:100;
    background-color: $primary-white;
  }

  .feed-container-noshow{
    visibility: hidden;
  }


  .notification-container {
    max-width: 1170px;
    margin: auto;
    width: 90%;
    height: 94%;
    overflow-x: hidden;
    overflow-y: scroll;
    position: absolute;
    top: 50px;
    scrollbar-width: thin;
  }

  .notification-container-noshow {
    visibility: hidden;
  }
}

h2{
  text-align: center;
}
.modal-container-show .modal {
  z-index: 1000000;
  width: 350px;
  height: 200px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: relative;
  animation: animate 0.3s;
}
.modal button {
  position: absolute;
  top: 7px;
  right: 7px;
  padding: 8px 11px;
  background: crimson;
  color: white;
  font-weight: bold;
  border: none;
  outline: none;
  border-radius: 2px;
  cursor: pointer;
}

.modal .button-save {
  position: absolute;
  bottom: 10%;
  height: 25px;
  background: white;
  color: black;
  text-align: center;
  top: auto;
  width: 100px;
  left: 15%;
  padding: 0px;
  font-weight: bold;
  border: 1px solid gray;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal .button-dont-save {
  position: absolute;
  bottom: 10%;
  height: 25px;
  background: white;
  color: black;
  text-align: center;
  top: auto;
  width: 100px;
  right: 15%;
  left: auto;
  padding: 0px;
  font-weight: bold;
  border: 1px solid gray;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

@keyframes animate {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}