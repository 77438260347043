@import "../../../styles/main.scss";

.interior-template__page {
  display: flex;
  position: absolute;
  background-color: #f3f8fd;
  min-height: 100vh;
  width: 100%;
}

.interior-template__side-nav {
  //float: right;
}

.interior-template__side-msg{
  position: relative;
  height: 100vh;
  width: 85px;
  overflow: hidden;
  box-shadow: -5px 0px 10px 1px #c2c2c242;
}

.interior-template__page-main-area {
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  // background-color: #E5E5E5;
  .interior-template__page-content {
    position: relative;
    width: 100%;
    padding: 20px;
    padding-top: 10px;
    flex: 1;
    height: 100vh;
    overflow: hidden;
    background-color: #f3f8fd;
    box-sizing: border-box;
  }

  .interior-template__page-drawer {
    flex: 1 1;
    position: absolute;
    z-index: 99999;
    background: white;
    right: 85px;
    padding: 30px;
    border: 1px solid lightgray;
    // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    height: 100vh;
    width: 600px;
    overflow: auto;
    box-sizing: border-box;

    .interior-template__page-drawer-title {
      margin-top: 20px;
    }

    .interior-template__page-drawer-description {
      margin-bottom: 20px;
    }

    .close-icon {
      position: absolute;
      font-size: 20px;
      top: 20px;
      left: 20px;
      color: $primary-grey;
      cursor: pointer;
      &:hover {
        color: $primary-grey;
      }
    }
  }
}

@media (max-width: $tablet) {
  .interior-template__page {
    flex-direction: column;
  }
}

@media (max-width: $mobile) {
  .hide-main-page-content-area {
    display: none;
  }
}
