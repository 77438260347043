.container {
   max-width: 1170px; 
     margin: auto; 
}

.containerr {
  width: 100%;
  height: 95%;
  left: 0px;
  position: relative;
  border-radius: 20px;
  background: white;
  border: 1px solid lightgrey;
}

@mixin box-shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
