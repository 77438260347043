@import "../../../../styles/main.scss";

.container_display-site-floorplan {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  .sidepanel_display-site-floorplan {
    width: 10%;
    background: white;
    z-index: 100;
    border: 1px solid lightgrey;
    position: absolute;
    border-radius: 5px;
    margin: 15px 5px;
    margin-left: 20px;
    .names-wrapper_sidepanel_display-site-floorplan {
      margin-top: 0px;
      .names_sidepanel_display-site-floorplan {
        cursor: pointer;
        margin-top: 0px;
        padding: 5px;
        text-align: center;
      }
      .names_sidepanel_display-site-floorplan:hover {
        background-color: $tertiary-grey;
        border-radius: 4px;
      }
    }
  }
  .display-area_display-site-floorplan {
    margin-top: 0%;
    margin-left: 0%;
  }

}
