@import "../../../../styles/main.scss";
.text-input__atom {
  margin: 8px 0;
  .label {
    margin: 4px 0;
  }
  input {
    width: 100%;
    box-sizing: border-box;
    padding-top: 4px;
    padding-right: 8px;
    padding-bottom: 4px;
    padding-left: 8px;
    background-color: #FFFFFF;
    outline: none;
    border: 1px solid #E9EAEA;
    border-radius: 4px;
    margin-bottom: 10px;
  } ::-webkit-input-placeholder{
    opacity: 35%;
  }

  .error {
    margin-top: -8px;
  }
}
