@import "../../../styles/main.scss";

.member-card__molecule {
  padding: 20px;
  margin: 10px;
  height: 180px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  min-width: 200px;
  align-items: center;
  row-gap: 10px;
  position: relative;
  // cursor: pointer;
  .member-profile-picture {
    height: 40px;
    width: 40px;
  }
  .member-profile-picture:hover{
    cursor: pointer;
    // border: 3px solid #116cd2;
  }
  .permissions-list {
    display: flex;
    column-gap: 10px;
  }
  .icon {
    color: $primary-red;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}

.member-card__molecule:hover {
  // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 13px -9px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 13px -9px rgba(0, 0, 0, 1);
  box-shadow: 0px 0px 13px -9px rgba(0, 0, 0, 1);
}

.inactive-member {
  background-color: $secondary-grey;
  cursor: not-allowed;
}
