@import "../../../styles/main.scss";

/* .profile-header-section_container {
  border: 1px solid $tertiary-grey;
  background: $secondary-grey;
  border-radius: 4px;
  .pic-info-and-buttons_container_profile-header-section {
    display: flex;
    position: relative;
    padding: 10px;
    .pic-and-info {
      .text_profile-header-section {
        margin-top: 10px;
      }
      .profileIcon_profile-header-section {
        width: 60%;
        height: 70%;
        border-radius: 4px;
      }
    }
    .buttons-container_profile-header-section {
      width: 30%;
      position: absolute;
      right: 10px;
      .button_profile-header-section {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
  .location-and-joining-date {
    border-top: 1px solid $tertiary-grey;
    // background: red;
    padding: 5px;
    display: flex;
    .location_profile-header-section {
      display: flex;
      margin-left: 10px;
      padding-right: 10px;
      border-right: 1px solid black;
      .icon-locationMarker_profile-header-section {
        margin-right: 5px;
        color: $primary-dark-blue;
      }
    }
    .joiningdate_profile-header-section {
      margin-left: 10px;
    }
  }
} */

.profile-header-section-container {
  font-family: Titillium Web;
  height: 410px;
  .profile-header-background {
    position: relative;
    height: 290px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background: url(../../../assets/images/1622849321438.jpg), #c4c4c4;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 30px;
    background-clip: padding-box;
  }

  .profile-header-user-info {
    position: relative;
    width: 75%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    bottom: 200px;
    border: solid black 3px;
    background: #ffffff;
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.07);
    border-radius: 10px;

    .profile-header-section-block {
      display: inline-block;
      width: 50px;
      height: 100%;
      margin-left: 7%;
      //background: #116cd2;
      z-index: 1;
    }

    .profileIcon_profile-header-section {
      position: relative;
      display: inline-block;
      border-radius: 50%;
      right: 87px;
      bottom: 60px;
      height: 175px;
      z-index: 2;
    }

    .text_profile-header-section-about {
      display: inline-block;
      font-family: Titillium Web;
      font-size: 18px;
      left: -5%;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      top: -55%;
      letter-spacing: 0em;
      text-align: left;
      width: 35%;
      position: relative;
    }

    .text_profile-header-section-buttons {
      display: inline-block;
      width: 12%;
      height: 140px;
      position: relative;
      bottom: 68px;
      margin: 0 auto;
      left: 55px;

      .button-container {
        display: flex;
        flex-flow: row wrap;
      }

      .button_profile-header-section {
        height: 48px;
        margin: 10px auto;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }

      .message {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 16px;
        background: #f3f8fd;
        border-radius: 8px;
        color: #116cd2;
      }

      .invite {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 16px;
        width: 211px;
        color: #ffffff;


        background: #116cd2;
        border-radius: 8px;
      }

      .add {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 16px;
        background: #FFFFFF;
        color: #116CD2;
      }
    }

    .profile-header-section-edit {
      display: inline-block;
      color: #a8a9aa;
      border: solid 1px rgba(168, 169, 170, 0.761);
      border-radius: 50%;
      padding: 10px;
      font-size: 18px;
    }
    /*    .text_profile-header-section-about{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    width: 17.03vw;
    position: absolute;

   } */
  }
}
