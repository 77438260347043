@import "../../../../../../styles/main.scss";

.create-site__page {
  .site-entry-area {
    display: flex;
    padding: 15px;
    .left-area {
      flex: 1;
      padding: 10px;
      .create-site__page-form {
        width: 300px;
        margin-top: 30px;
      }
    }
    .right-area {
      flex: 2;
      margin: 20px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      .map {
        height: 500px;
      }
      .user-instruction-mapwithsearch {
        padding: 4px;
        text-align: center;
        background-color: $secondary-teal;
      }
    }
  }
}
