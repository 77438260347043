$desktop: 1300px;
$tablet: 769px;
$mobile: 480px;

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $secondary-grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $tertiary-grey;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary-grey;
}
