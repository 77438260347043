@import "../../../../../styles/main.scss";

.circle-marker {
    border-radius: 40%;
	border: 3px solid $primary-dark-blue;
}

.you-marker {
	z-index: 0;
}

.popup-info {
	z-index: -1;
}

.marker-above-popup {
	width: 1px; /* Adjust width as needed for marker size */
	height: 1px; /* Adjust height as needed for marker size */
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0; /* Higher z-index ensures marker stays above popup */
  }